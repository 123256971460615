<template>
  <component :is="currentLoginComponent" />
</template>

<script>
  import LoginDefault from './LoginDefault.vue';
  import LoginIG from './LoginIG.vue';
  import LoginICNA from "@/module/auth/LoginICNA.vue"
  import LoginAbrapa from "@/module/auth/LoginAbrapa.vue"

  export default {
    computed: {
      currentLoginComponent() {
        const domain = window.location.hostname;
        switch (domain) {
          case 'coffeetrace.agtrace.ag':
            return LoginIG;
          case 'agritrace.agtrace.ag':
            return LoginICNA;
          case 'souabr.agtrace.ag':
            return LoginAbrapa;
          default:
            return LoginDefault;
        }
      }
    }
  };
</script>