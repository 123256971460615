<template>
  <v-app>
    <splash></splash>
    <v-row align='center' justify='center'>
      <v-col cols='3' class='pt-0 pb-0 ground-rigth'>
        <div class='ground-left left-content'>
          <v-row  class='my-4' align='end' justify='center'>
            <v-img height='150' class='mt-6 logo' contain :src='logo' />
          </v-row>
          <v-row class='my-4' align='start' justify='center'>
            <v-form ref='form' v-model='valid' class='form' justify='center'>
              <v-text-field onkeypress='return (event.charCode != 34)' v-on:keyup.86='replaceEmail'
                            v-on:keyup.ctrl.86='replaceEmail'
                            :label="$t('auth.fields.username')"
                            :rules='requireRules' class='required'
                            v-model='credentials.email'
                            color='primary'
                            :dark='false'
                            outlined
                            dense
                            append-icon='mdi-account'
              />
              <v-text-field onkeypress='return (event.charCode != 34)' v-on:keyup.86='replacePassword'
                            v-on:keyup.ctrl.86='replacePassword'
                            dense
                            outlined
                            :label="$t('auth.fields.password')"
                            :type="showPass ? 'text' : 'password'"
                            :rules='requireRules' class='required'
                            v-model='credentials.password'
                            @click:append='showPass = !showPass'
                            @keyup.enter='loginAction'
                            color='primary'
                            :dark='false'
                            append-icon='mdi-eye'
              />
              <v-btn :loading='load'
                     class='mt-12'
                     block dark
                     color='primary'
                     @click='loginAction'
                     :disabled='!valid'
                     :dark='false'>
                {{ $t('auth.fields.login') }}
              </v-btn>
            </v-form>
          </v-row>
          <v-row  align='end' justify='center'>
            <v-col >
              <div class='subheading footer mt-6 '>© Copyright AgTrace.</div>
              <div class='body-1 footer'>{{ $t('login.footer') }}</div>
            </v-col>
          </v-row>
        </div>
      </v-col>
      <v-col cols='9' class='ground-rigth'>
        <v-row style='height: 90%'>
          <v-col cols='12' >
            <v-container >
              <v-card
                color='transparent'
                height='90%'
                elevation='0'
              >
                <v-card-title>
                  <v-spacer></v-spacer>
                  <language-select :dense='true' style='width: 76px' :flagW='23' :flagH='15'
                                   :background="'transparent'" :flat='true' :dark='true'>
                  </language-select>
                </v-card-title>
                <v-card-text  class='mt-6 card-text' align='center'>
                  <h5 class='title'>{{ $t('login.title') }}</h5>
                  <h4 class='text-center subtitle pt-5'>{{ $t('login.sub_title') }}</h4>
                  <a href='https://agtrace.ag/contato/'>
                    <v-btn :loading='load' class='mt-6' color='primary'>
                      {{ $t('auth.fields.contact_us') }}
                    </v-btn>
                  </a>
                </v-card-text>
              </v-card>
            </v-container>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-app>
</template>

<script>
  import { mapActions, mapGetters, mapState } from 'vuex'
  import LanguageSelect from '@/components/LanguajeSelect'
  import Splash from "@/components/Splash.vue"

  export default {
    name: 'LoginDefault',
    components: {
      Splash,
      LanguageSelect,
    },
    meta: {
      title: 'Login',
    },
    data: () => ({
      // Form validation
      valid: false,
      // Show password model
      showPass: false,
      // Load assets
      background: require('../../assets/img/login/background.jpg'),
      logo: require('@/assets/img/login/logo-agtrace.png')
    }),

    created() {
      this.$store.commit('general/SET_LOADING_OFF', false);
    },

    methods: {
      ...mapActions({
        login: 'login/login',
      }),
      async loginAction() {
        try {
          await this.login(this.$toast);
          console.log('login OK')
        } catch(e) {
          console.log(e);
        }
      },
      replace(event) {
        event.currentTarget.value = event.currentTarget.value.toString().replaceAll('"', '')
      },
      replaceEmail(event) {
        this.credentials.email = event.currentTarget.value.toString().replaceAll('"', '')
      },
      replacePassword(event) {
        this.credentials.password = event.currentTarget.value.toString().replaceAll('"', '')
      },
    },
    computed: {
      ...mapGetters({
        requireRules: 'login/requireRules',
        load: 'login/loading',
      }),
      ...mapState({
        credentialsState: (state) => state.login.credentials,
      }),
      credentials: {
        get() {
          return this.credentialsState
        },
        set(data) {
          this.$store.commit('login/SET_ITEM_SELECTED', { data })
        },
      },
    },
  }
</script>

<style scoped lang='scss'>
  @import '../../../src/assets/css/variables.scss';

  .theme--light.v-btn.v-btn--disabled {
    color: $primary;
  }

  .v-application {
    overflow: hidden;
  }

  .v-application a {
    color: $primary;
  }

  .title-coffee{
    display: flex;
    justify-content: center;
    color: $primary;
    font-family: "Poppins", Sans-serif, serif !important;;
    font-size: 25px !important;;
    font-weight: bold !important;
    text-align: center;
  }
  .main-title-coffee{
    display: flex;
    justify-content: center;
    font-family: "Poppins", Sans-serif, serif !important;;
    font-size: 40px !important;
    font-weight: bold !important;
    color: #FBFBFB;
  }

  .left-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .ground-rigth {
    height: 100vh;
    background: url(../../assets/img/login/background.jpg) no-repeat center center fixed;
    background-size: 100% 100%;
    padding-right: 0;
  }

  .ground-left {
    height: 100vh;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.9);
    background-size: 100% 100%;
  }

  .logo {
    filter: brightness(100%);
  }

  .form {
    width: 60%;
  }

  .title {
    color: #ffffff !important;
    font-family: "Poppins", Sans-serif, serif !important;;
    font-size: 40px !important;;
    font-weight: bold !important;
  }

  .subtitle {
    color: #ffffff !important;
    font-family: "Poppins", Sans-serif, serif !important;;
    font-size: 18px !important;
    font-weight: normal !important;
    width: 80%;
  }

  .footer {
    color: #111111 !important;
    font-family: "Poppins", Sans-serif, serif !important;;
    font-size: 12px !important;
    font-weight: normal !important;
    display: flex;
    justify-content: center;
  }
  .footer-2{
    color: #ffffff !important;
    font-family: "Poppins", Sans-serif, serif !important;;
    font-size: 12px !important;
    font-weight: normal !important;
  }

  .logo-footer {
    height: 100%;
    width: 450px;
  }

  .image-container {
    display: flex;
    justify-content: center;
  }


  form {
    .material-form__field {
      label {
        font-size: 16px;
        color: white;
      }

      input {
        font-size: 17px;
        color: $metal !important;
      }
    }
  }

  @media only screen and (min-width: 1080px) {
    .card-text {
      position: relative;
      height: 60%;
    }
  }

  /* Desktops and laptops ----------- */
  @media only screen and (min-width: 1224px) {
    .card-text {
      position: relative;
      height: 70%;
    }
  }

  /* Large screens ----------- */
  @media only screen and (min-width: 1824px) {
    .card-text {
      position: relative;
      height: 80%;
    }
  }
</style>
